<template>
    <div>
      <FormStateCity :item-data="info"></FormStateCity>
    </div>
  </template>
  
  <script>
  
  import FormStateCity from '@core/components/state-cities/FormStateCity.vue'
  
  export default {
    components: {
        FormStateCity,
    },
    data() {
      return {
        info: {},
      }
    },
  }
  </script>
  
  <style>
  </style>
  