<template>
  <div>
    <b-card>
      <!-- Media -->
      <b-media class="mb-2">

        <h3 class="mb-2">
          <span v-if="infoPlace.id !== null">Edit Record</span>
          <span v-if="infoPlace.id === null">Save Record</span>
        </h3>

      </b-media>

      <validation-observer ref="formRules" tag="form">
        <!-- form Admin -->
        <b-form ref="registerForm">
          <b-row>
            <!-- Field: State -->
            <b-col cols="12" md="3">
              <b-form-group label="State">
                <validation-provider #default="{ errors }" rules="required" name="State">
                  <v-select v-model="selectedState" :options="listStates" label="title" placeholder="" />
                  <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3">
              <b-form-group label="City">
                <validation-provider #default="{ errors }" rules="required" name="city">
                  <b-form-input v-model="infoPlace.city" :state="errors.length > 0 ? false : null" maxlength="200" />
                  <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Time zone">
                <validation-provider #default="{ errors }" rules="required" name="time_zone">
                  <v-select v-model="selectedTimeZone" :options="optionsTimezone" label="title" placeholder="" />
                  <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="mb-2" style="text-align: right;">

            <b-button :variant="inhabilitar === true ? 'primary' : 'primary'" class="ml-1" @click="saveChanges"
              v-if="infoPlace.id === null">
              <span class="d-none d-sm-inline">Save</span>
              <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
            </b-button>

            <b-button :variant="inhabilitar === true ? 'primary' : 'primary'" class="ml-1" @click="saveChanges"
              v-if="infoPlace.id !== null">
              <span class="d-none d-sm-inline">Update</span>
              <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
            </b-button>
          </div>
        </b-form>
      </validation-observer>

    </b-card>

  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
  BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { getMessageError } from "@core/utils/utils";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import moment from "moment"
import Cleave from 'vue-cleave-component'
import { getMenuTimeZones } from "@core/utils/menus";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormTextarea,
    BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    moment,
    Cleave
  },
  props: {
    itemData: {
    },
  },
  data() {
    return {
      infoPlace: { id: null, state_full:"", city:"" },
      inhabilitar: true,
      options: {
        numeral: {
          numeral: true,
          numeralDecimalMark: '.',
          delimiter: '',
          numeralIntegerScale: 5
        },
      },
      selectedTimeZone: { value: "" },
      optionsTimezone: [],
      listStates: [],
      selectedState: { value: "", title: "" },
    }
  },
  methods: {
    saveChanges() {
      this.validationForm();
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.formRules.validate()
          .then(success => {
            if (success) {
              this.formSubmit()
            }
          })
      })
    },
    formSubmit() {
      let data = { ...this.infoPlace };
      data.time_zone = this.selectedTimeZone.value;
      data.state_full=this.selectedState.value;

      data.state_full=data.state_full.toUpperCase();
      data.city=data.city.toUpperCase();
      
      this.$swal({
        title: 'Please, wait...',
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      let url = ""

      //if is add action
      if (this.infoPlace.id === null) {
        url = `states_cities/add_city_state`
      } else {
        //if is update action
        url = `states_cities/modify_city_state`
      }

      let message = this.infoPlace.id > 0 ? "Record modified successfully" : "Record added successfully"
      this.$http.post(url, data)
        .then((res) => {
          if (res.data.status === 200) {
            if (this.infoPlace.id === undefined) {
              this.$refs.formRules.reset()
              this.infoPlace = {}
            }

            this.$swal({
              title: message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.$router.push({ name: 'states-cities' })
          } else {
            this.$swal({
              title: res.data.message.data,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    async getInfo(id) {
      await this.$http
        .get(`states_cities/get_citie_state/${id}`)
        .then((response) => {
          this.infoPlace = response.data.data;
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    async getStates() {
      this.listStates = [];
      this.selectedState = null;
      await this.$http
        .get(`states_cities/get_states`)
        .then((response) => {
          this.loadMenuStates(response.data.data);
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    loadMenuStates(listData) {
      this.listStates = [];
      listData.forEach((element) => {
        let data = {
          value: `${element.state_full}`,
          title: `${element.state_full}`,
        };
        this.listStates.push(data);
      });
    },
    async loadInfo() {
      let id = this.$route.params.id;
      this.optionsTimezone = getMenuTimeZones();
      this.getStates();
      if(id){
        await this.getInfo(id);
      }
  
      this.selectedState = this.listStates.find((x) => x.value ==  this.infoPlace.state_full);
      this.selectedTimeZone = this.optionsTimezone.find((x) => x.value == this.infoPlace.time_zone);
    },
  },
  mounted() {
    this.loadInfo();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.text-name-driver {
  font-size: 1.5rem;
  margin-bottom: 0 !important;
}

.backgroundProfile-driver {
  background-color: $primary;
}
</style>
